@use '@carbon/react';
.cds--inline-notification {
  max-width: none;
  width: 60vw;

  .cds--inline-notification__details {
    display: flex;
    align-items: center;
    .cds--inline-notification__text-wrapper {
      flex-direction: row-reverse;
      align-items: center;
    }
    .cds--inline-notification__title {
      text-align: center;
    }

    .cds--inline-notification__icon {
      display: none;
      margin: 0
    }
  }
  .cds--inline-notification__title {
    font-size: clamp(1rem, 0.88rem + 0.6000000000000001vw, 1.6rem);
    margin-left: 1rem;

  }

}

.cds--text-input-wrapper {
  .cds--form__helper-text {
    color: #C6C6C6;
  }

  .cds--text-input {
    background-color: transparent;
    color: white;
  }
  .cds--text-input::placeholder {
    background-color: transparent;
    color: #C6C6C6;
  }
}
.cds--label {
  font-family: "IBM Plex Sans", serif;
  color: #C6C6C6;
  font-size: clamp(1rem, 0.88rem + 0.6000000000000001vw, 1.6rem);
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}
.cds--select-input {
  background-color: transparent;
}

.cds--tab-content {
  padding: 0;
}

.cds--tabs .cds--tabs__nav-link {
  color: #C6C6C6;
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);
  max-width: unset;
}
.cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected, .cds--tabs .cds--tabs__nav-item--selected, .cds--tabs .cds--tabs__nav-item--selected:focus .cds--tabs__nav-link:focus, .cds--tabs .cds--tabs__nav-item--selected:active .cds--tabs__nav-link:active {
  color: #ffffff;
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);

}

.cds--data-table-header__title {
  font-size: clamp(2rem, 1.6vw + 1.2rem, 3.2rem);
  color: #fff;
  margin-bottom: 2rem;
}

.cds--data-table-header__description {
  max-width: 80ch;
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);
  color: #C6C6C6;
  line-height: 2rem;

}

.cds--data-table .cds--table-header-label {
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);
}

.cds--modal-container {
  background-color:#393939 ;

}
.cds--modal-header__heading {
  color: #fff;
}
.cds--modal-footer {
  display: none;
}

.cds--file-filename {
  color: #000;
}

.cds--form__helper-text {
  font-size: 1rem;
}

.cds--btn {
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);
}

.cds--text-input--md {
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);

}

.cds--form__helper-text {
  color: #C6C6C6;
}

.cds--select-input {
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);
  color: #C6C6C6;

}

.cds--select-option {
  color: #C6C6C6;
}

.cds--data-table-header__description{
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);

}

.cds--checkbox-label-text {
  font-size: 1.6rem;
}

.cds--checkbox-label::before {
  border: 1px solid #fff

}
.cds--tabs .cds--tabs__nav-item {
  background-color: #323436;
}
.cds--tabs .cds--tabs__nav-item--selected {
  background-color: #4f4f4f;
}

.cds--data-table-header {
  background: #4f4f4f;
  padding: 3rem 0 3rem 2rem;
}



.test {
}
